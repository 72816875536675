

const navEL = document.querySelector('.navbar');

window.addEventListener('scroll', ()=> {
    if (window.scrollY >=56){
        navEL.classList.add ('navbar-scrolled')
    }
    else if (window.scrollY<56){
        navEL.classList.remove('navbar-scrolled');
    }
});



var $galleryContainer = $('.gallery').isotope({
    itemSelector: '.item',
      layoutMode: 'fitRows'
})

$('.portfolio-filters  .button').on('click', function(){
    $('.portfolio-filters  .button').removeClass('active');
    $(this).addClass('active');

    var value = $(this).attr('data-filter');
    $galleryContainer.isotope({
        filter: value 
    })
})


document.addEventListener("DOMContentLoaded", function() {
    const currentLocation = window.location.href;
    const navLinks = document.querySelectorAll('.navbar-nav .nav-link');

    navLinks.forEach(function(link) {
      if (link.href === currentLocation) {
        link.classList.add('active');
      }
    });
  });


// JavaScript
function countTo(target, element) {
  var current = 0;
  var step = Math.ceil(target / 150); // Adjust the speed of counting by changing the divisor
  var timer = setInterval(function() {
      current += step;
      if (current >= target) {
          clearInterval(timer);
          current = target;
      }
      element.textContent = current;
  }, 20); // Adjust the interval for smoother counting
}

function startCounting(entries, observer) {
  entries.forEach(entry => {
      if (entry.isIntersecting) {
          entry.target.classList.add('show');
          var target = parseInt(entry.target.querySelector('.count').textContent);
          countTo(target, entry.target.querySelector('.count'));
          observer.unobserve(entry.target);
      }
  });
}

const statsSection = document.querySelector('#stats');
const statsObserver = new IntersectionObserver(startCounting, { threshold: 0.5 });
const statsElements = document.querySelectorAll('.stats-item');
statsElements.forEach(element => statsObserver.observe(element));


function aosInit() {
  AOS.init({
    duration: 600,
    easing: 'ease-in-out',
    once: true,
    mirror: false
  });
}
window.addEventListener('load', aosInit);

const cloneEl = document.querySelector(".logos-slide").cloneNode(true)
const logos = document.querySelector('.logos').appendChild(cloneEl);
